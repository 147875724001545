<template>
    <span>{{elapsedTime}}</span>
</template>

<script>
import {elapsedSecondsToTimeStr, getTimeSyncFactor, getSyncedElapsedSeconds} from '../../utils/TimeSync'

export default {
    name: 'OSListTimer',
    props: {
        elapsedHoursDecimal: {type: Number, default: 0},
        noteStartTimestamp: Object,
        noteStartElapsedSeconds: {type: Number, default: 0}
    },
    data() {
        return{
            elapsedSeconds: 0,
            timeDiff: 0,
            timer: null
        }
    },
    computed: {
        elapsedTime() {
            return elapsedSecondsToTimeStr(this.elapsedSeconds)
        }
    },
    watch: {
        noteStartTimestamp() {
            this.initTimerValues()
        }
    },
    created() {
        this.initTimerValues()
        this.setTimer()
    },
    methods: {
        initTimerValues() {
            this.elapsedSeconds = this.elapsedHoursDecimal*3600 + this.noteStartElapsedSeconds
            this.timeDiff = getTimeSyncFactor(this.noteStartTimestamp, this.noteStartElapsedSeconds)
        },
        setTimer() {
            this.timer = window.setTimeout(() => {
                var now = new Date()
                this.elapsedSeconds = this.elapsedHoursDecimal*3600 + getSyncedElapsedSeconds(now,this.noteStartTimestamp, this.timeDiff)
                this.setTimer()
            }, 1000) 
        },  
        clearTimer() {
            window.clearTimeout(this.timer)
        }
    },
    unmounted() {
        this.clearTimer()
    }
}
</script>