<template>
    <tr class="table-row-backgorund" :class="{'text-working': isWorking, 'text-stopped': isStopped, 'text-late': isLate}">
        <td class="text-center">{{column.number}}</td>
        <td>{{column.cards}}</td>
        <td class="text-center">{{column.vehicle_plate}}</td>
        <td class="td-text-overflow td-maxwidth-22">{{column.vehicle_details}}</td>
        <td class="text-center">{{startTime}}</td>
        <td class="text-center">{{expectedTime}}</td>
        <td class="text-center">
            <OSListTimer 
                :elapsedHoursDecimal="column.elapsed_hours_decimal"
                :noteStartElapsedSeconds="column.note_start_elapsed_seconds"
                :noteStartTimestamp="column.note_start_timestamp"
                v-if="column.note_start_timestamp"/>
            <template v-else>{{elapsedTime}}</template>
        </td>
        <td class="td-text-overflow td-maxwidth-7">{{column.resource_name}}</td>
        <td>{{column.descr}}</td>
    </tr>
</template>

<script>
import {elapsedSecondsToTimeStr} from '../../utils/TimeSync'
import OSListTimer from '../../components/OSList/OSListTimer.vue'

export default {
    name: 'OSListRow',
    props:{
        column: Object
    },
    computed: {
        startTime() {
            const ts = this.column.start_timestamp
            return `${String(ts.getDate()).padStart(2, '0')}/${String(ts.getMonth() + 1).padStart(2, '0')}/${String(ts.getFullYear())} ${String(ts.getHours()).padStart(2, '0')}:${String(ts.getMinutes()).padStart(2, '0')}:${String(ts.getSeconds()).padStart(2, '0')}`
        },
        expectedTime() {
            const ts = this.column.expected_end_timestamp
            return `${String(ts.getDate()).padStart(2, '0')}/${String(ts.getMonth() + 1).padStart(2, '0')}/${String(ts.getFullYear())} ${String(ts.getHours()).padStart(2, '0')}:${String(ts.getMinutes()).padStart(2, '0')}:${String(ts.getSeconds()).padStart(2, '0')}`
        },
        elapsedTime() {
            return elapsedSecondsToTimeStr(this.column.elapsed_hours_decimal*3600)
        },
        isWorking() {
            return this.column.status === 0
        },
        isStopped() {
            return this.column.status === 1
        },
        isLate() {
            return this.column.status === 1 && this.column.expected_end_timestamp.getTime() < Date.now()
        },

    },
    components: {OSListTimer}
}
</script>

<style scoped>
    .table-row-backgorund {background-color: #323232}
    .text-working {color: #50cc50}
    .text-stopped {color: #f8df6e} 
    .text-late {color: #fc6d6d}
    td {border: 0.01px solid #4d4d4d; padding:0.4vw}
    .td-text-overflow {white-space:nowrap; overflow:hidden; text-overflow:ellipsis}
    .td-maxwidth-7 {max-width: 7vw}
    .td-maxwidth-22 {max-width: 22vw}
</style>