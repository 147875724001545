const Handler = require('./Handler')
const backendCodes = require('../../backend_codes')

class DashboardUpdatedHandler extends Handler {
    _doHandle(action) {
        return action === backendCodes.DASHBOARD_UPDATED
    }

    _execute(msg) {
        this._data.columns.splice(0)
        const oss = msg.content.map(os => {
            os.start_timestamp = new Date(os.start_timestamp)
            os.expected_end_timestamp = new Date(os.expected_end_timestamp)

            if (os.note_start_timestamp)
                os.note_start_timestamp = new Date(os.note_start_timestamp)

            return os
        })
        this._data.columns = oss
        this._data.companyName = msg.company_name

        this._data.showModal = false
    }
}

module.exports = DashboardUpdatedHandler