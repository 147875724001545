const Handler = require('./Handler')
const dashboardCodes = require('../../backend_codes')

class OSListOSChangedHandler extends Handler {
    _doHandle(action) {
        return action === dashboardCodes.OS_CHANGED
    }

    _execute(msg) {
       // console.log('_execute')        
        const idx = this._data.columns.findIndex(e => e.id === msg.content.id)
        if (idx === -1) 
            return

        msg.content.expected_end_timestamp = new Date(msg.content.expected_end_timestamp)  

        if (msg.content.note_start_timestamp)
            msg.content.note_start_timestamp = new Date(msg.content.note_start_timestamp)

        this._data.columns[idx] = {
            ...this._data.columns[idx],
            ...msg.content
        }
    }
}

class OSListOSStatusHandler extends Handler {
    _doHandle(action) {
        return action === dashboardCodes.OS_STATUS_CHANGED
    }

    _execute(msg) {
        let os = this._data.columns.find(e => e.id === msg.content.id)
        if (!os)
            return
        
        os.status = msg.content.status
        os.resource_name = msg.content.resource_name
        os.elapsed_hours_decimal = msg.content.elapsed_hours_decimal

        if (msg.content.note_start_timestamp) {
            os.note_start_timestamp = new Date(msg.content.note_start_timestamp)
            os.note_start_elapsed_seconds = msg.content.note_start_elapsed_seconds
        }
        else {
            os.note_start_timestamp = null
            os.note_start_elapsed_seconds = null
        }
    }
}

class OSListOSRemovedHandler extends Handler {
    _doHandle(action) {
        return action === dashboardCodes.OS_REMOVED
    }

    _execute(msg) {
        const idx = this._data.columns.findIndex(e => e.id === msg.os)
        if (idx === -1)
            return
        
        this._data.columns.splice(idx, 1)
    }
}

class OSListOSAddedHandler extends Handler {
    _doHandle(action) {
        return action === dashboardCodes.OS_ADDED
    }

    _execute(msg) {
        msg.content.expected_end_timestamp = new Date(msg.content.expected_end_timestamp)
        msg.content.start_timestamp = new Date(msg.content.start_timestamp)

        if (msg.content.note_start_timestamp)
            msg.content.note_start_timestamp = new Date(msg.content.note_start_timestamp)

        this._data.columns.push(msg.content)
    }
}

module.exports = {OSListOSChangedHandler, OSListOSStatusHandler, OSListOSRemovedHandler, OSListOSAddedHandler}