const OSListDashboardHandler = require('./OSListDashboardHandler')
const {OSListOSChangedHandler, OSListOSStatusHandler, OSListOSRemovedHandler, OSListOSAddedHandler} = require('./OSListOSHandler')

function HandlersFactory(dashboardInstance) {
    return [
        new OSListDashboardHandler(dashboardInstance),
        new OSListOSChangedHandler(dashboardInstance),
        new OSListOSStatusHandler(dashboardInstance),
        new OSListOSRemovedHandler(dashboardInstance),
        new OSListOSAddedHandler(dashboardInstance)
    ]
}

module.exports = HandlersFactory