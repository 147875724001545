function inject() {
   // console.log('SIMULATOR')
    return [
        {
            "id": 51,
            "number": "17/21",
            "status": 1,
            "cards": "1; 3",
            "vehicle_plate": "ABC-1234",
            "vehicle_details": "Volkswagen/santana Cg _2p/4p_",
            "start_timestamp": new Date("2021-09-06T16:27:12.707432"),
            "expected_end_timestamp": new Date("2021-09-08T16:27:12.707432"),
            "elapsed_hours_decimal": 28.1051,
            "resource_name": "",
            "descr": "teste ipsum dolor sit amet, consectetur adipiscing elit. Etiam e"
        },
        {
            "id": 56,
            "number": "21/21",
            "status": 1,
            "cards": "1001",
            "vehicle_plate": null,
            "vehicle_details": null,
            "start_timestamp": new Date("2021-11-01T11:54:15.503"),
            "expected_end_timestamp": new Date("2021-11-03T11:53:54.556"),
            "elapsed_hours_decimal": null,
            "resource_name": "",
            "descr": "teste do alexandre obs fora da os"
        },
        {
            "id": 53,
            "number": "19/21",
            "status": 0,
            "cards": "1003; 4",
            "vehicle_plate": "NKX-8019",
            "vehicle_details": "Mitsubishi L200 Savana 2.5 4x4 121cv Cd Dti Dies.",
            "start_timestamp": new Date("2021-09-10T15:35:05.808244"),
            "expected_end_timestamp": new Date("2021-09-13T15:35:05.808244"),
            "elapsed_hours_decimal": 0.0455,
            "resource_name": "Teste Jr; Recurso #2",
            "descr": null
        }
    ]
}

module.exports = inject